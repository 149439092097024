@import 'core/variables';
@import 'core/mixins';

// Core Components
@import 'core/misc';

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import 'plugins/plugin-nouislider';
@import 'plugins/plugin-react-datetime';
@import 'plugins/plugin-react-slick';

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 10000;
}

// .MuiAppBar-root.MuiAppBar-colorPrimary {
//   color: #fff;
//   background-color: #5cb913;
// }

.pictureReorderList > div {
  display: flex;
  padding: 0rem 1rem;
}

.categoryMultiSelectArea .MuiCheckbox-root {
  padding: 5px 12px;
}

.se-dialog-tabs [data-tab-link='url'] {
  display: none;
}

#userInfoDetailArea .MuiTab-labelIcon {
  border: solid 1px #555555;
}

#userInfoDetailArea .MuiTab-labelIcon.Mui-selected {
  border: none;
}

.svgCategoryIcon {
  fill: #555555;
  height: 30px;
}

.MuiTab-labelIcon.Mui-selected .svgCategoryIcon {
  fill: #ffffff;
  border: none !important;
  height: 30px;
}

.autoCompleteField {
  margin-top: 1rem;
}

.rightChatMsg {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
  & > div {
    background-color: #4ea952;
    padding: 0.6rem 1rem;
    border-radius: 0.5rem;
    max-width: 85%;
  }
  .dateTime {
    font-size: 70%;
    color: #eeeeee;
    text-align: right;
  }
}

.leftChatMsg {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
  & > div {
    background-color: #ab47bc;
    padding: 0.6rem 1rem;
    border-radius: 0.5rem;
    max-width: 85%;
  }
  .dateTime {
    font-size: 70%;
    color: #eeeeee;
    text-align: right;
  }
}

.slick-track {
  display: flex;
}

.slick-track .slick-slide {
  flex-shrink: 0;
}

@media screen and (max-width: 959px) {
  #navbar {
    .MuiList-root {
      display: flex;
      flex-direction: column;
      #searchMenuArea {
        order: 1;
      }
      .MuiListItem-root {
        order: 2;
      }
    }
  }
}
